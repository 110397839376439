import React from 'react';
import {Col} from 'react-bootstrap';

export const RadioCard = (props) => {
  
    const images = require.context('../assets/radio/', true);
    
    var img
    if( props.station.logo === undefined)
      img = images('./radio-logo.png'); 
    else
      img = images('./' + props.station.logo);

    return(
      <Col width="80px" className='station-col'>
        <img src={img} alt="" width='100%' title={props.station.name} onClick={(e) => props.pressPlay(props.station)} style={{cursor: 'pointer'}}/>
      </Col>
    )
   

}