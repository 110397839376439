import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import styled from 'styled-components';

const Styles = styled.div`
    .navbar {
        background-color: #646;
        width: 100%;
    }

    .navbar-brand, .navbar-nav .nav-link {
        color: #bbb;
        
        &:hover {
            color: white;
        }
    }
`;

export const NavigationBar = () => {
    return(
        <Styles>
            <Navbar variant="dark" expand="lg">
                <Navbar.Brand href="/">ribberink.com</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto"> 
                       <Nav.Item><Nav.Link href="/radio">Radio</Nav.Link></Nav.Item> 
                       <Nav.Item><Nav.Link href="/host">Host</Nav.Link></Nav.Item> 
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Styles>
    )
}