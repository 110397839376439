import React from 'react'

export const Host = () => {
    return(<HostInfo/>)
}

class HostInfo extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
              headers: []
        };
     }

    componentDidMount() {
        var targetUrl = 'https://api.ribberink.com/host/api_host_request'
        fetch(targetUrl)
          .then(response => response.json())
          .then(headers => this.setState({ headers }));
    }

    render() { 
        
        return (
            Object.keys(this.state.headers).map( key => {
                if( key.startsWith("X-Am"))
                    return undefined
                if( key.startsWith("Via"))
                    return undefined    
                var name1 = key.replace("CloudFront-", "")
            return <div>{name1}: {this.state.headers[key]}</div>
           })
        )
    }
}