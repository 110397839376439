import React from 'react';
import { RadioCard } from './components/RadioCard';
import { RadioListItem } from './components/RadioListItem';
import { RadioPlayer } from './components/RadioPlayer';
import data from './assets/data';
import { Row, Col, Form, ButtonToolbar, ButtonGroup, ToggleButton, Table} from 'react-bootstrap';
import videojs from 'video.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faThLarge } from '@fortawesome/free-solid-svg-icons';


import './assets/radio.scss';

export const Radio = () => {
   return (<RadioStations />)
}

const videoJsOptions = {
   controls: true,
   controlBar: {
      fullscreenToggle: false,
      pictureInPictureToggle: false
   },
   height: 150,
   width: 150
};

class RadioStations extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
         stations: data,
         textFilter: undefined,
         thLarge: true
      };
   }

   

   onClick(station) {
      console.debug(station);
      var type = station.type ? station.type : 'audio/mpeg';
      var logo = station.logo ? station.logo : 'radio-logo.png';
      this.setState(
         {
            src: station.url,
            poster: require('./assets/radio/' + logo),
            type: type,
            name: station.name            
         });
      var player = videojs('my-player');
      var playstation = {
         src: station.url,
         type: type
      }
      player.src(playstation);
      player.play();
      document.title = station.name + ' - Internet Radio';
   }

   setTextFilter(e) {
      this.setState({
         textFilter: e.target.value.trim(),
      })
   }

   toggleTh(listView) {
      setTimeout(() => {
         this.setState({
            thLarge: !listView
         });
      }, 50);
   }

   playStation(station) {
      this.onClick(station)
   }

   render() {
      var stations = data
      if (this.state.textFilter && this.state.textFilter !== '')
         stations = stations.filter((station) =>
            (station.region && station.region.toLowerCase().includes(this.state.textFilter.toLowerCase())) ||
            (station.name && station.name.toLowerCase().includes(this.state.textFilter.toLowerCase())) ||
            (station.type && station.type.toLowerCase().includes(this.state.textFilter.toLowerCase()))
         )

      var listview   
      if (this.state.thLarge) {
        listview = (<Row xs={3} sm={3} md={4} lg={6} xl={6} className='station-wrapper'>
        { stations.map((station) => <RadioCard station={station} pressPlay={this.playStation.bind(this)} key={'rc' + station.key}/>) }  
      </Row>)
      } else {
         const columns = ['logo', 'name', 'region', 'type']
         listview = ( <React.Fragment><Table striped bordered hover size="sm" columns={columns} date={stations}>
         
         <thead>
           <tr>
             <th>Logo</th>
             <th>Name</th>
             <th>Region</th>
             <th>Type</th>
           </tr>
         </thead>
         <tbody>
            {stations.map((station) => <RadioListItem station={station} pressPlay={this.playStation.bind(this)} key={'li' + station.key}/>)}
         </tbody>
         </Table>
         <Table striped bordered hover size="sm" columns={columns} data={stations}></Table>
         </React.Fragment>
            )
      }

      return (
         <React.Fragment>
            <Row xs={3} sm={3} md={4} lg={6} xl={6}>
               <Col className='player-wrapper' height="250px">
                  <RadioPlayer ref={this.player} {...videoJsOptions} poster={this.state.poster} name={this.state.name} />
               </Col>
               <Col>&nbsp;</Col>
               <Col>
                  <Form>
                     <Form.Group controlId="formPlaintextEmail">
                        <Form.Label column>
                           Search
                        </Form.Label>
                        <Col>
                           <Form.Control name="textFilter" value={this.state.textFilter} onChange={this.setTextFilter.bind(this)} />
                        </Col>
                        <Col>
                           <br/>
                           <ToggleViewButtons listview={!this.state.thLarge} toggleHandler={this.toggleTh.bind(this)} />
                        </Col>
                     </Form.Group>

                  </Form>

               </Col>
            </Row>
            {listview}
              

         </React.Fragment>
      )
   }



}

function ToggleViewButtons(props) {

   const isListView = props.listview;
   const toggleHandler = props.toggleHandler;
   
   const radios = [
      { name: 'Thumbnails', value: false, icon: faThLarge },
      { name: 'List', value: true, icon: faList }
   ];

   return(<ButtonToolbar>
      <ButtonGroup toggle>
         {radios.map((radio, idx) => (
            <ToggleButton
               key={idx}
               type="radio"
               variant="light"
               name="radio"
               value={radio.value}
               checked={radio.value === isListView}
               onClick={(e) => toggleHandler(radio.value)}
            >
            <FontAwesomeIcon icon={radio.icon}  />
            </ToggleButton>
         ))}
      </ButtonGroup>
   </ButtonToolbar>)

}