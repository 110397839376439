import React from 'react'

export const NotFound = () => {
    return (
        <meta http-equiv="refresh" content="0; URL='/'" />
    )
}

export const Redirect = () => {
    const redirect = "0; URL='https://ribberink.com" + window.location.pathname + "'";
    return (
        <meta http-equiv="refresh" content={redirect} />
    )
}

