import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'; 
import {Home} from './Home';
import {Radio} from './Radio';
import {Host} from './Host';
import {NotFound, Redirect} from './Error';
import {Layout} from './components/Layout';
import {NavigationBar} from './components/NavigationBar';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  if( window.location.hostname !== "ribberink.com" && 
      window.location.hostname !== "localhost" )
    return (<Redirect/>)
  else  
    return (
        <React.Fragment>
          <NavigationBar />
          <Layout>
            <Router>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/radio" component={Radio} />
                <Route exact path="/host" component={Host} />
                <Route component={NotFound} />
              </Switch>
            </Router>
          </Layout>
        </React.Fragment>
    );
}

export default App;
