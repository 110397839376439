import React from 'react';
import videojs from 'video.js';
import 'dashjs';
import 'videojs-contrib-dash';
import 'video.js/dist/video-js.css';

export class RadioPlayer extends React.Component {

   constructor(props) {
      super(props);
      this.state = {dummy: 'test'};
    }

   componentDidMount() {
      // instantiate Video.js
      this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
        console.log('onPlayerReady', this)
      });
    }
  
    // destroy player on unmount
    componentWillUnmount() {
      if (this.player) {
        this.player.dispose()        
      }
    }
  
    render() {  
      return (
      <div>	
          <div data-vjs-player>
            <video id="my-player" ref={ node => this.videoNode = node } poster={this.props.poster} className="video-js" width="200" height="200"></video>
          </div>
      </div>
      )
    }

  
}