import React from 'react';

export const RadioListItem = (props) => {
  
    const images = require.context('../assets/radio/', true);
    
    var img
    if( props.station.logo === undefined)
      img = images('./radio-logo.png'); 
    else
      img = images('./' + props.station.logo);

    

    var contentType;
    switch(props.station.type) {
      case "application/x-mpegURL":
        contentType = <img src={images('./hls.png')} width="70px" alt={props.station.type}/>;
        break; 
      case "application/dash+xml":
        contentType = <img src={images('./dash.png')} width="100px" alt={props.station.type}/>;
        break; 
      case "audio/aac":
      case "audio/aacp":
        contentType = <img src={images('./aac.png')} width="65px" alt={props.station.type}/>;
        break; 
      default:
        props.station.type = "audio/mpeg"
        contentType = <img src={images('./mp3.png')} width="50px" alt={props.station.type}/>;
    }
    
    return(
      <tr onClick={(e) => props.pressPlay(props.station)}>
        <td><img src={img} width="50px" alt="logo"/></td>
        <td>{props.station.name}</td>
        <td>{props.station.region}</td>
        <td style={{ textAlign: "center" }}>{contentType}</td>
      </tr>
    )
   

}